@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: url(../fonts/Material_Icons-normal-400.woff) format('woff');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	src: url(../fonts/Roboto-normal-300.woff) format('woff');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: url(../fonts/Roboto-normal-400.woff) format('woff');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	src: url(../fonts/Roboto-normal-500.woff) format('woff');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	src: url(../fonts/Roboto-normal-700.woff) format('woff');
}