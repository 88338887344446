
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}


a, a:hover, a:focus {
    color: #D0021B;
}

.page-header {
    border-bottom: 1px solid #E7E7E7;
    color: #000;
}
#page-wrapper {
    border-left: none;
    background-color:#F8F8F8;
}


/*
 * Login
 */

.login-panel {    
    background: #fff url("img/logo-login.png") no-repeat scroll 2.5em 1.5em;
    padding: 100px 1.5em 1.5em;
}

@media (min-width: 768px) {
    
    body.login {
        background: #F8F8F8 url(img/logo-fons.svg) no-repeat 2em 5em;
        background-size: 80%;
    }    
    .login-panel {
        margin-top: 50%;
    }
}

@media (min-width: 970px) {
    
    body.login {
        background: #F8F8F8 url(img/logo-fons.svg) no-repeat 2em 5em;
        background-size: 60%;
    }    
}

@media (min-width: 1170px) {
    .login-panel {
        margin-top: 60%;
    }
    body.login {
        background: #F8F8F8 url(img/logo-fons.svg) no-repeat 25% 3em;
        background-size: 40%;
    }    
}
/*
/*
 * Dashboard
 */

.home #page-wrapper {margin: 1em 0;}
.home .navbar-default.sidebar {display: none;}
.home .navbar-right {right: 0;}

.home .panels {
    margin-top: 2em;
    font-weight: 400 !important;
}
.home .panels h2, .home .panels h4 {font-weight: 400 !important;}
.home .panel {background-color: #918585; border-radius: 0; margin-bottom:2em;}
.home .panel-heading {
    padding: 20px 25px;
}
.home .panel-heading a:hover {color:#4A4A4A; text-decoration:none;}

    .home .panel-heading .huge {
        font-size: 40px;
        line-height: 100%;
    }
    .home .panel > .panel-heading, .home  .panel.panel-default > .panel-heading {background: transparent;}

    .home .panel a {color: #fff;}
    .home .panel h2 {margin: 0;}
    
.home .panel.panel-admin {background-color: #DEDEDE;}
.home .panel.panel-admin > .panel-heading, .home .panel.panel-admin > .panel-heading a { color: #4A4A4A;}

    .home .panel.panel-admin h2{ margin-bottom: 1em; text-align:center; margin: 0 0 1em;}
    .home .panel.panel-admin .fa {color:#918585;}
    .home .panel.panel-admin a:hover{color:#000; text-decoration:none;}
    .home .panel.panel-admin .left-item { border-right: 1px solid #AAAAAA;}
    .home .panel.panel-admin .right-item { border-left: 1px solid #fff;}


    




/*
 * Nav bar
 */
.navbar, .navbar.navbar-default {
    min-height: 75px;
    border-bottom: 1px solid #e7e7e7;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.84);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
}

.navbar .navbar-brand {
    /*height: 55px;
    padding: 5px 15px;*/
    padding: 15px;
    height: 80px;
}

.navbar-toggle {margin-top: 22px;}
.navbar .navbar-top-links { margin-top: 10px;}

.navbar .navbar-brand img{
    height: 44px;
}

.nav > li > a:focus,.nav > li > a:hover {
    background: none;
}
.nav .open > a, .nav .open > a:focus, .nav .open > a:hover { border: none; background: none;}
.navbar .dropdown-menu li > a:hover, .navbar.navbar-default .dropdown-menu li > a:hover, .navbar .dropdown-menu li > a:focus, .navbar.navbar-default .dropdown-menu li > a:focus {
    background-color: #eeeeee;
    color: #D0021B;
}

.navbar-right .user {color:#333;}
.navbar-right .user .fa {color:#D0021B;}
.navbar-right .user .fa.fa-user {color:#000; font-size: 20px;}


@media (max-width: 767px) {
    .navbar-right {
        position:absolute;
        top: 0;
        right: 50px;
    }

}

@media (min-width: 768px) {
    .sidebar {
        margin-top: 83px;
    }

}

/*
 * Side menu
 */

#side-menu .fa {color: #000;}
#side-menu .fa.fa-fw {font-size: 1.35em; margin-right: 8px;}

.sidebar ul li {
    border-bottom: 1px solid #d3d3d3;
}
    .sidebar ul li a {
        background-color: #EEEEEE;
    }    
        .sidebar ul li a.active {
            background-color: #F8F8F8;
        }
.sidebar .arrow {
    font-size: 20px;
}
@media (max-width: 767px) {
    .sidebar-nav {display: none;}
}
/*
 * Chosen
 */
.chosen-container {
    font-size: 16px;
    margin: 3px 0;
}
.chosen-container-single .chosen-single {
    height: 34px;
    padding-top: 3px;
}
    .chosen-container-single .chosen-single abbr{
        top: 11px;
    }
    .chosen-container-single .chosen-single div{
        top: 5px;
    }
.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
    height: 30px;
}

.chosen-container-single .chosen-single {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #d2d2d2;
    background: none;
    box-shadow: none;
}

.chosen-container .chosen-results li.highlighted {
    background-color: #3875d7;
    background-image: linear-gradient(#009688 20%, #009688 90%);
    color: #fff;
}

.chosen-container-active .chosen-choices {
    border: 1px solid #d2d2d2;
    box-shadow: none;
}
.chosen-container-multi .chosen-choices li.search-choice {
    background-image: none;
    border-radius: 0;
    box-shadow: none;
    margin-top: 5px;
}
/* verd #009688 */


/*
 * Formularis
 */
p.mostrant {margin-top: 18px;}

.listSearchForm .form-group {margin:0;}

.pagination > li > a, .pagination > li > span {color:#D0021B;}
.pagination > li > a:focus ,.pagination > li > a:hover , .pagination > li > span:focus, .pagination > li > span:hover {color:#D0021B;}
.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
    background-color: #D0021B;
    border-color: #D0021B;
}
.form-group label.control-label {
    font-size: 14px;
}
.form-group .checkbox label, .form-group .radio label, .form-group label,
.checkbox label, .radio label, label {
    color: #666;
}

    
    .telefons .form-control {
        width: auto;
        margin-right: 10px;
    }
    .telefons .form-group label.control-label {margin-right: 20px;}
    .telefons .phone_type {display:inline;}
    .telefons .phone_number {display:inline;}

div.error {
    color: #f44336;
}
/*
 * Cerques
 */

a.edit .material-icons {font-size: 18px;}
.form-group.form-group-larger label.control-label{
    font-size:20px;    
}
.form-group.form-group-larger input.form-control{
    font-size:24px;
    
}


.contact .contact_list span{
    display:block;
    margin-bottom: 1em;
    margin-left: 50px;
    
}
    .contact .contact_list span.icon {
        float: left;
        margin: 0;
    }
    .contact .contact_list span.icon .material-icons {
        font-size: 2em; 
    }
    .contact .contact_list span a{
        font-size: 18px; 
    }
    
    .contact p.mitja {text-transform: uppercase;}
    .contact ul.twocolumns {
        -webkit-column-count: 2; /* Chrome, Safari, Opera */
        -moz-column-count: 2; /* Firefox */
        column-count: 2;
        list-style-type:none;
        margin-bottom: 1em;
        margin-left: 50px;
        padding-left: 0;
    }
    .contact ul.twocolumns li { padding-bottom: .5em; }
    

@media (min-width: 768px) {
    .contact .contact_list {
        margin-top: 20px;
    }

}

.btn.btn-fab.btn-fab-xs {
    height: 30px;
    min-width: 30px;
    width: 30px;    
}
    .btn.btn-fab.btn-fab-xs i {font-size: 17px; line-height:30px;} 
div.social .btn-fab-xs {margin-right: 10px;}    
